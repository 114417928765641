<template>
  <div>
    <section id="card-text-alignment">
      <b-row class="">
        <!-- left align -->
        <b-col
          lg="12"
        >
          <b-card 
            title="How to find the companies you're looking for"
            class="mb-0 mt-20"
          >
            <b-card-text class="mt-20">
              You might be familiar with the Terms: Current Ratio, Price to Earnings Ratio and the Price to Earnings Ratio,. If you do not know them yet, you can click below to learn what these terms mean and why the are the most relevant to measure profitability of a specific Company.
            </b-card-text>
            
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-center
              variant="outline-primary"
            >
              Explain it to me
            </b-button>
            <b-modal
              id="modal-center"
              centered
              title="Vertically Centered"
              ok-only
              ok-title="Accept"
            >
              <b-card-text>
              Price to Earnings Ratio (P/E Ratio):
              Think of the P/E ratio as the price tag on a toy. It tells you how much investors are willing to pay for each dollar the company earns. A high P/E might mean the toy is popular, but it doesn't always mean it's a good deal.
              Earnings Growth:
              This is like watching a plant grow. Earnings are the money a company makes, and earnings growth is how much that money is increasing each year. Just like a growing plant is a good sign, growing earnings show a healthy and thriving company.
              Price to Book Ratio:
              Imagine you're buying a used book. The price you're willing to pay for it compared to its original price is like the Price to Book ratio. If it's close to the original price, it might be a good deal. If it's much higher, you might be paying too much.
              Current Ratio:
              Think of the current ratio as a safety net. It shows whether a company can cover its immediate bills. If you have more money in your piggy bank than you owe to your friends, you're in good shape. Similarly, a high current ratio means the company can easily cover its short-term debts.
              </b-card-text>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
  
</template>

<script>
import {  doc, getDoc } from "firebase/firestore"
import { onBeforeMount } from "vue-demi"
import  {db}  from '@/firebase/index.js'
 import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { BModal, VBModal, BAlert} from 'bootstrap-vue'



export default {

  components: {
    BRow, BCol, BCard, BCardText, BButton,BModal, VBModal, BAlert
  },
  directives: {
    Ripple, 'b-modal': VBModal,
  
  },
}
</script>
