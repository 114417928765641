// import firebase 
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDj6zyHvy0VYV9Fm4OvIIC26suYwAa6pCo",
  authDomain: "test-database-4fa09.firebaseapp.com",
  projectId: "test-database-4fa09",
  storageBucket: "test-database-4fa09.appspot.com",
  messagingSenderId: "288768737059",
  appId: "1:288768737059:web:e3b491d32f344b93c4d696",
  measurementId: "G-WNW974N4H1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
import { getFirestore } from "firebase/firestore";
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app)

// const CheckoutButton = document.getElementById('checkout-button')
// CheckoutButton.addEventListener('click', ()=>{
//   console.log('Clicked!')
// })

export  {
  db,
  auth,
  
}

