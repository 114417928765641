<template>
  <b-card>
      <div>
        <b-card-text>
          <h3>Graham Criterias</h3>
        </b-card-text>
          <div class="row">
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">Earnings Growth :</label>
                  <b-form-spinbutton
                    disabled
                    class="mb-5"
                    id="demo-sb"
                    v-model="growth"
                    min="1"
                    max="100"/>
            </div>
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">Current Ratio :</label>
                  <b-form-spinbutton
                    disabled
                    id="demo-sb"
                    v-model="Current"
                    min="1"
                    max="100"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb"> Price to Earnings Ratio :</label>
                  <b-form-spinbutton
                    disabled
                    id="demo-sb"
                    v-model="PERATIO"
                    min="1"
                    max="100"/>
            </div>
            <div class="col-md-6 mb-4">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">Price to Book Ratio :</label>
                  <b-form-spinbutton
                    disabled
                    id="demo-sb"
                    v-model="PBRATIO"
                    min="1"
                    max="100"
                    step="0.5"/>
            </div>
          </div>
        <b-row class="mt-4">
              <p class="card-text"><small class="text-muted">These Values are provided by Benjamin Graham in his book the intelligent Investor. Keep in mind that this Screener <h6>DOES NOT REPLACE INVESTIAGTING IN YOUR OWN RESEARCH</h6></small></p>
      </b-row>
      </div>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BFormSpinbutton, BAlert, BCardText } from 'bootstrap-vue'
import { codeBasic } from './code'

export default {
  components: {
    BFormSpinbutton,
    BCardCode,
    BCardText,
    BAlert,
  },
  data() {
    return {
      value: 50,
      growth: 3,
      Current: 2,
      PERATIO: 15,
      PBRATIO: 11.5,
      codeBasic,
    }
  },
}
</script>

<style>
.b-form-spinbutton {
  border-radius: 15px; /* Set the border radius to 15px */
}
</style>